/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';

export function DropdownMenuExport() {
    return (
        <>
            {/*begin::Navigation*/}
            <ul className="navi navi-hover">
                <li className="navi-header pb-1">
                    <span className="text-primary text-uppercase font-weight-bold font-size-sm">
                        Export to:
                    </span>
                </li>
                <li className="navi-item">
                    <a href="#" className="navi-link">
                        <span className="navi-icon">
                            <i className="flaticon2-file"></i>
                        </span>
                        <span className="navi-text">PDF</span>
                    </a>
                </li>
                <li className="navi-item">
                    <a href="#" className="navi-link">
                        <span className="navi-icon">
                            <i className="flaticon2-sheet"></i>
                        </span>
                        <span className="navi-text">Excel</span>
                    </a>
                </li>
                <li className="navi-item">
                    <a href="#" className="navi-link">
                        <span className="navi-icon">
                            <i className="flaticon2-calendar-3"></i>
                        </span>
                        <span className="navi-text">CSV</span>
                    </a>
                </li>
                <li className="navi-item">
                    <a href="#" className="navi-link">
                        <span className="navi-icon">
                            <i className="flaticon2-print"></i>
                        </span>
                        <span className="navi-text">Print</span>
                    </a>
                </li>
            </ul>
            {/*end::Navigation*/}
        </>
    );
}
