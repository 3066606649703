/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useMemo } from 'react';
import { Nav, Tab, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import SVG from 'react-inlinesvg';
import objectPath from 'object-path';
import { useHtmlClassService } from '../../../_core/MetronicLayout';
import { toAbsoluteUrl } from '../../../../_helpers';
import { DropdownTopbarItemToggler } from '../../../../_partials/dropdowns';

import { Badge, makeStyles } from '@material-ui/core';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';

const perfectScrollbarOptions = {
    wheelSpeed: 2,
    wheelPropagation: false,
};

const useStyles = makeStyles(theme => ({
    margin: {
        margin: theme.spacing(2),
    },
}));

export function UserNotificationsDropdown() {
    const [key, setKey] = useState('Notifications');
    const bgImage = toAbsoluteUrl('/media/misc/bg-1.jpg');

    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            offcanvas:
                objectPath.get(
                    uiService.config,
                    'extras.notifications.layout',
                ) === 'offcanvas',
        };
    }, [uiService]);
    const classes = useStyles();

    return (
        <>
            {layoutProps.offcanvas && (
                <div className="topbar-item">
                    <div
                        className="btn btn-icon btn-clean btn-lg mr-1"
                        id="kt_quick_notifications_toggle"
                    >
                        <Badge
                            className={classes.margin}
                            color="primary"
                            variant="dot"
                        >
                            {/* <NotificationsNoneIcon style={{ color: '#00abf2' }} /> */}
                            <span className="svg-icon svg-icon-xl svg-icon-primary">
                                <SVG
                                    src={toAbsoluteUrl(
                                        '/media/svg/icons/General/Notifications1.svg',
                                    )}
                                />
                            </span>
                        </Badge>
                    </div>
                </div>
            )}
            {!layoutProps.offcanvas && (
                <Dropdown drop="down" alignRight>
                    <Dropdown.Toggle
                        as={DropdownTopbarItemToggler}
                        id="kt_quick_notifications_toggle"
                    >
                        <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip id="user-notification-tooltip">
                                    Notifications
                                </Tooltip>
                            }
                        >
                            <div
                                className="btn btn-icon btn-clean btn-lg mr-5 btn-pill"
                                id="kt_quick_notifications_toggle"
                            >
                                <Badge
                                    className={classes.margin}
                                    color="error"
                                    variant="dot"
                                >
                                    <span className="svg-icon svg-icon-xl svg-icon-primary">
                                        <SVG
                                            src={toAbsoluteUrl(
                                                '/media/svg/icons/General/Notifications1.svg',
                                            )}
                                        />
                                    </span>
                                </Badge>
                            </div>
                        </OverlayTrigger>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
                        <form>
                            {/** Head */}
                            <div
                                className="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
                                // style={{ backgroundImage: `url(${bgImage})` }}
                                style={{ background: `var(--cs-primary)` }}
                            >
                                <h4 className="d-flex flex-center rounded-top mb-12">
                                    <span className="text-white">
                                        User Notifications
                                    </span>
                                    {/* <span className="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2">
                    23 new
                  </span> */}
                                </h4>

                                <Tab.Container
                                    defaultActiveKey={'Notifications'}
                                >
                                    <Tab.Content className="tab-content">
                                        <Tab.Pane
                                            eventKey="Notifications"
                                            id="topbar_notifications_events"
                                        >
                                            <PerfectScrollbar
                                                options={
                                                    perfectScrollbarOptions
                                                }
                                                className="navi navi-hover scroll my-4 d-none"
                                                style={{
                                                    maxHeight: '300px',
                                                    position: 'relative',
                                                }}
                                            >
                                                <a
                                                    href="#"
                                                    className="navi-item"
                                                >
                                                    <div className="navi-link">
                                                        <div className="navi-icon mr-2">
                                                            <i className="flaticon2-line-chart text-success"></i>
                                                        </div>
                                                        <div className="navi-text">
                                                            <div className="font-weight-bold">
                                                                New report has
                                                                been received
                                                            </div>
                                                            <div className="text-muted">
                                                                23 hrs ago
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>

                                                <a
                                                    href="#"
                                                    className="navi-item"
                                                >
                                                    <div className="navi-link">
                                                        <div className="navi-icon mr-2">
                                                            <i className="flaticon2-paper-plane text-danger"></i>
                                                        </div>
                                                        <div className="navi-text">
                                                            <div className="font-weight-bold">
                                                                Finance report
                                                                has been
                                                                generated
                                                            </div>
                                                            <div className="text-muted">
                                                                25 hrs ago
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>

                                                <a
                                                    href="#"
                                                    className="navi-item"
                                                >
                                                    <div className="navi-link">
                                                        <div className="navi-icon mr-2">
                                                            <i className="flaticon2-user flaticon2-line- text-success"></i>
                                                        </div>
                                                        <div className="navi-text">
                                                            <div className="font-weight-bold">
                                                                New order has
                                                                been received
                                                            </div>
                                                            <div className="text-muted">
                                                                2 hrs ago
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>

                                                <a
                                                    href="#"
                                                    className="navi-item"
                                                >
                                                    <div className="navi-link">
                                                        <div className="navi-icon mr-2">
                                                            <i className="flaticon2-pin text-primary"></i>
                                                        </div>
                                                        <div className="navi-text">
                                                            <div className="font-weight-bold">
                                                                New customer is
                                                                registered
                                                            </div>
                                                            <div className="text-muted">
                                                                3 hrs ago
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>

                                                <a
                                                    href="#"
                                                    className="navi-item"
                                                >
                                                    <div className="navi-link">
                                                        <div className="navi-icon mr-2">
                                                            <i className="flaticon2-sms text-danger"></i>
                                                        </div>
                                                        <div className="navi-text">
                                                            <div className="font-weight-bold">
                                                                Application has
                                                                been approved
                                                            </div>
                                                            <div className="text-muted">
                                                                3 hrs ago
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>

                                                <a
                                                    href="#"
                                                    className="navi-item"
                                                >
                                                    <div className="navi-link">
                                                        <div className="navi-icon mr-2">
                                                            <i className="flaticon2-pie-chart-3 text-warning"></i>
                                                        </div>
                                                        <div className="navinavinavi-text">
                                                            <div className="font-weight-bold">
                                                                New file has
                                                                been uploaded
                                                            </div>
                                                            <div className="text-muted">
                                                                5 hrs ago
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </PerfectScrollbar>
                                            <div className="d-flex flex-center text-center text-muted min-h-200px">
                                                All caught up!
                                                <br />
                                                No new notifications.
                                            </div>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                            </div>
                        </form>
                    </Dropdown.Menu>
                </Dropdown>
            )}
        </>
    );
}
