import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';
import * as auth from '../app/modules/Auth/_redux/authRedux';
import { rolesSlice } from '../app/modules/UserManagement/_redux/roles/rolesSlice';
import { usersSlice } from '../app/modules/UserManagement/_redux/users/usersSlice';
import {
    propertySlice,
    ticketSlice,
    unitSlice,
    allUnitSlice,
    employerSlice,
    tenantSlice,
    meetingsSlice,
    quickStatsSlice,
    recentActivitySlice,
    depositsVsWithdrawalsSlice,
    loansStatsSlice,
    communicationStatsSlice,
    propertiesSummaryStatsSlice,
    incomeSlice,
    propertyDataByLandlordSlice,
} from '../app/data-widgets/_redux/widgetsSlice';
import {
    countriesSlice,
    groupInfoSlice,
    membersInfoSlice,
    unitTypesSlice,
    unitsSlice,
    groupContributionsSlice,
    groupLoansSlice,
    groupAccountsSlice,
    currenciesSlice,
    propertyConfigurationSlice,
    propertyTicketsSlice,
    propertyUtilitiesSlice,
    groupConfirmSlice,
    propertyTypesSlice,
    newTenantsSlice,
} from '../app/modules/Onboard/_redux/onboardSlice';
import {
    invoicesSlice,
    paymentsSlice,
    paymentsHistorySlice,
    landlordPaymentsAnalyticsSlice,
} from '../app/modules/Invoices/_redux/invoicesSlice';
import { propertiesSlice } from '../app/modules/Properties/_redux/propertiesSlice';
import { agentsSlice } from '../app/modules/Agents/_redux/agentsSlice';
import { tenantsSlice } from '../app/modules/Tenants/_redux/tenantsSlice';
import { ticketsSlice } from '../app/modules/Tickets/_redux/ticketsSlice';
import { expensesSlice } from '../app/modules/Expenses/_redux/all-expenses/expensesSlice';
import { expenseCategoriesSlice } from '../app/modules/Expenses/_redux/categories/categoriesSlice';
import { financialsSlice } from '../app/modules/Financials/_redux/financialsSlice';
import {
    checkinSlice,
    systemProfileSlice,
    systemServicesSlice,
    systemThemeSlice,
    systemUsersSlice,
    systemSettingsSlice,
    countiesSlice,
} from '../app/modules/Setup/_redux/setupSlice';
import {
    tenantsReportSlice,
    propertiesReportSlice,
    incomesExpensesReportSlice,
} from '../app/modules/Reports/_redux/reportsSlice';

import { customersSlice } from '../app/modules/ECommerce/_redux/customers/customersSlice';
import { productsSlice } from '../app/modules/ECommerce/_redux/products/productsSlice';

export const rootReducer = combineReducers({
    auth: auth.reducer,
    roles: rolesSlice.reducer,
    users: usersSlice.reducer,
    countries: countriesSlice.reducer,
    properties: propertiesSlice.reducer,
    agents: agentsSlice.reducer,
    tenants: tenantsSlice.reducer,
    newTenants: newTenantsSlice.reducer,
    unitTypes: unitTypesSlice.reducer,
    units: unitsSlice.reducer,
    propertyTypes: propertyTypesSlice.reducer,
    financials: financialsSlice.reducer,

    // ===== Start: Setup widgets =====
    systemProfile: systemProfileSlice.reducer,
    systemTheme: systemThemeSlice.reducer,
    systemServices: systemServicesSlice.reducer,
    systemUsers: systemUsersSlice.reducer,
    systemSettings: systemSettingsSlice.reducer,
    counties: countiesSlice.reducer,
    // ===== End: Setup widgets =======

    // ===== Start: Dashboard widgets =====

    property: propertySlice.reducer,
    propertyDataBylandlord: propertyDataByLandlordSlice.reducer,
    income: incomeSlice.reducer,
    ticket: ticketSlice.reducer,
    tickets: ticketsSlice.reducer,
    expenses: expensesSlice.reducer,
    expenseCategories: expenseCategoriesSlice.reducer,
    unit: unitSlice.reducer,
    allUnit: allUnitSlice.reducer,
    employer: employerSlice.reducer,
    tenant: tenantSlice.reducer,
    meetings: meetingsSlice.reducer,
    quickStats: quickStatsSlice.reducer,
    recentActivity: recentActivitySlice.reducer,
    depositsVsWithdrawals: depositsVsWithdrawalsSlice.reducer,
    loansStats: loansStatsSlice.reducer,
    communicationStats: communicationStatsSlice.reducer,
    propertySummaryStats: propertiesSummaryStatsSlice.reducer,
    // ===== End: Dashboard widgets =======

    // ===== Start: Invoices ====
    invoices: invoicesSlice.reducer,
    billingPayments: paymentsSlice.reducer,
    paymentsHistory: paymentsHistorySlice.reducer,
    paymentsAnalytics: landlordPaymentsAnalyticsSlice.reducer,
    // ===== End: Invoices ====

    // ===== Start: Add Propertys slices =====
    groupInfo: groupInfoSlice.reducer,
    membersInfo: membersInfoSlice.reducer,
    groupContributions: groupContributionsSlice.reducer,
    groupLoans: groupLoansSlice.reducer,
    groupAccounts: groupAccountsSlice.reducer,
    currencies: currenciesSlice.reducer,
    propertyConfigurations: propertyConfigurationSlice.reducer,
    propertyUtilities: propertyUtilitiesSlice.reducer,
    propertyTickets: propertyTicketsSlice.reducer,
    groupConfirm: groupConfirmSlice.reducer,
    // ===== End: Add Propertys slices =====

    // ===== Start: Reports slices =====
    tenantsReport: tenantsReportSlice.reducer,
    propertiesReport: propertiesReportSlice.reducer,
    incomesExpensesReportSlice: incomesExpensesReportSlice.reducer,
    // ===== End: Reports slices =====

    //    Ecommerce
    customers: customersSlice.reducer,
    products: productsSlice.reducer,

    checkin: checkinSlice.reducer,
});

export function* rootSaga() {
    yield all([auth.saga()]);
}
