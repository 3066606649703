/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
// import SVG from "react-inlinesvg";
// import { toAbsoluteUrl } from "../../../../_helpers";

export function BreadCrumbs({ items }) {
    if (!items || !items.length) {
        return '';
    }

    return (
        <ul className="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2">
            {/* <li className="breadcrumb-item">
        <Link to="/">
          <span className="svg-icon svg-icon-sm menu-icon svg-icon-dark-50"><SVG src={toAbsoluteUrl("/media/svg/icons/Layout/Layout-4-blocks.svg")} /></span>
        </Link>
      </li> */}
            {items.map((item, index) => (
                <li key={`bc${index}`} className="breadcrumb-item">
                    <Link
                        className="text-muted"
                        to={{ pathname: item.pathname }}
                    >
                        <div dangerouslySetInnerHTML={{ __html: item.title }} />
                    </Link>
                </li>
            ))}
        </ul>
    );
}
