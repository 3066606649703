import { createSlice } from '@reduxjs/toolkit';

const initialInvoicesState = {
    actionsLoading: false,
    invoices: [],
    invoice: {},
    totalInvoiceAmount: '',
};

const initialPaymentsState = {
    actionsLoading: false,
    payments: [],
    payment: {},
    totalPaymentAmount: '',
};

const initialPaymentsHistoryState = {
    actionsLoading: false,
    paymentsHistory: {},
};

export const invoicesSlice = createSlice({
    name: 'invoices',
    initialState: initialInvoicesState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        invoicesFetched: (state, action) => {
            const { invoices, totalInvoiceAmount } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.invoices = invoices;
            state.totalInvoiceAmount = totalInvoiceAmount;
        },
        propertyFetched: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.invoice = action.payload.data[0];
        },
    },
});

export const paymentsSlice = createSlice({
    name: 'billingPayments',
    initialState: initialPaymentsState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        paymentsFetched: (state, action) => {
            const { payments, totalPaymentAmount } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.payments = payments;
            state.totalPaymentAmount = totalPaymentAmount;
        },
    },
});

export const paymentsHistorySlice = createSlice({
    name: 'paymentsHistory',
    initialState: initialPaymentsHistoryState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        paymentsHistoryFetched: (state, action) => {
            const { paymentsHistory } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.paymentsHistory = paymentsHistory;
        },
    },
});

export const landlordPaymentsAnalyticsSlice = createSlice({
    name: 'landlordPaymentsAnalytics',
    initialState: {
        actionsLoading: false,
        paymentsAnalytics: {},
    },
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        paymentsAnalyticsFetched: (state, action) => {
            const { paymentsAnalytics } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.paymentsAnalytics = paymentsAnalytics;
        },
    },
});
