import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { LayoutSplashScreen } from '../../../../_chamasoft/layout';
import * as auth from '../_redux/authRedux';
import { appConfigs } from '../../../../_chamasoft/_helpers';

class Logout extends Component {
    componentDidMount() {
        window.location.replace(appConfigs.auth_url + '/logout');
        this.props.logout();
    }

    render() {
        const { hasAuthToken } = this.props;
        return hasAuthToken ? (
            <LayoutSplashScreen />
        ) : (
            <Redirect to="/auth/redirect" />
        );
    }
}

export default connect(
    ({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }),
    auth.actions,
)(Logout);
