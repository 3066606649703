import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import * as auth from '../_redux/authRedux';
import { verifyUser } from '../_redux/authCrud';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

function AuthRedirect(props) {
    document.title = 'Verifying...';
    let { token } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    verifyUser(token)
        .then(res => {
            // console.log(res.data.result_code);
            if (res.data.result_code === 1) {
                dispatch(auth.actions.login(res.data.user.access_token));
                dispatch(auth.actions.setUser(res.data.user));
                history.push('/');
            } else {
                //console.log('logout');
                window.location.replace('/logout');
            }
        })
        .catch(err => {
            console.log(new Error(err).message);
            //window.location.replace(appConfigs.auth_url + '/logout');
        });

    return (
        <div className="kt-splash-screen">
            <svg className="splash-spinner" viewBox="0 0 50 50">
                <circle
                    className="path"
                    cx="25"
                    cy="25"
                    r="20"
                    fill="none"
                    strokeWidth="5"
                ></circle>
            </svg>
            <p style={{ margin: '20px' }}>Verifying, please wait...</p>
        </div>
    );
}

export default injectIntl(connect(null, auth.actions)(AuthRedirect));
