/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useState } from 'react';
import { Tab, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import SVG from 'react-inlinesvg';
import objectPath from 'object-path';
import { useHtmlClassService } from '../../../_core/MetronicLayout';
import { appConfigs, toAbsoluteUrl } from '../../../../_helpers';
import { DropdownTopbarItemToggler } from '../../../../_partials/dropdowns';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { checkinUser } from '../../../../../app/modules/Auth/_redux/authCrud';
import * as auth from '../../../../../app/modules/Auth/_redux/authRedux';
import * as setupActions from '../../../../../app/modules/Setup/_redux/setupActions';

const perfectScrollbarOptions = {
    wheelSpeed: 2,
    wheelPropagation: false,
};

export function SwitcherDropdown() {
    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            offcanvas:
                objectPath.get(
                    uiService.config,
                    'extras.notifications.layout',
                ) === 'offcanvas',
        };
    }, [uiService]);

    const {
        fetchingData,
        profiles,
        fetchingSettings,
        settings,
        bank,
        user,
    } = useSelector(
        state => ({
            fetchingData: state.checkin.actionsLoading,
            profiles: state.checkin.profiles,
            fetchingSettings: state.systemSettings.actionsLoading,
            settings: state.systemSettings.settings,
            bank: state.systemProfile.data,
            user: state.auth.user,
        }),
        shallowEqual,
    );

    const host = window.location.hostname;
    const [selected, setSelected] = useState(null);
    const dispatch = useDispatch();

    const switchOption = (profile, i) => {
        if (
            profiles[i].is_validated === 0 ||
            profiles[i]._id === bank?._id ||
            (host === 'localhost' &&
                appConfigs.slug !==
                    (profiles[i].profile === 'cs-rentals'
                        ? 'rentals'
                        : 'error'))
        )
            return;
        setSelected(i);
        // console.log(profile);
        checkinUser(user.access_token, profile)
            .then(res => {
                if (res.data.status === true) {
                    // console.log(res.data.user);
                    if (profiles[i].profile !== `cs-${appConfigs.slug}`) {
                        dispatch(auth.actions.setToken(undefined));
                        dispatch(auth.actions.setUser(undefined));
                        let url =
                            profiles[i].profile === 'cs-bank'
                                ? appConfigs.bank_home_url
                                : appConfigs.ngo_home_url;
                        window.location.href =
                            url +
                            '/auth/verify-user/' +
                            res.data.user.redirect_string;
                    } else {
                        // dispatch(auth.actions.login(res.data.user.access_token));
                        dispatch(
                            auth.actions.setToken(res.data.user.access_token),
                        );
                        dispatch(auth.actions.setUser(res.data.user));

                        dispatch(setupActions.fetchSystemProfile());
                        dispatch(setupActions.fetchSystemTheme());
                        dispatch(
                            setupActions.fetchProfiles(
                                res.data.user.access_token,
                            ),
                        );
                        dispatch(setupActions.fetchSystemSettings());
                        // window.location.reload();
                    }
                }
            })
            .catch(err => console.log(err))
            .finally(() => setSelected(null));
    };

    return (
        <>
            {layoutProps.offcanvas &&
                !fetchingSettings &&
                settings?.hideAppSwitcher !== 'true' && (
                    <div className="topbar-item">
                        <div
                            className="btn btn-icon btn-clean btn-lg mr-1"
                            id="kt_quick_switcher_toggle"
                        >
                            <span className="svg-icon svg-icon-xl svg-icon-primary">
                                <SVG
                                    src={toAbsoluteUrl(
                                        '/media/svg/icons/Layout/Layout-4-blocks.svg',
                                    )}
                                />
                            </span>
                        </div>
                    </div>
                )}
            {!layoutProps.offcanvas &&
                !fetchingSettings &&
                settings?.hideAppSwitcher !== 'true' && (
                    <Dropdown drop="down" alignRight>
                        <Dropdown.Toggle
                            as={DropdownTopbarItemToggler}
                            id="kt_quick_switcher_toggle"
                        >
                            <OverlayTrigger
                                placement="bottom"
                                overlay={
                                    <Tooltip id="user-notification-tooltip">
                                        Options
                                    </Tooltip>
                                }
                            >
                                <div
                                    className="btn btn-icon btn-clean btn-lg mr-5 btn-pill"
                                    id="kt_quick_switcher_toggle"
                                >
                                    <span className="svg-icon svg-icon-xl svg-icon-primary">
                                        <SVG
                                            src={toAbsoluteUrl(
                                                '/media/svg/icons/Layout/Layout-4-blocks.svg',
                                            )}
                                        />
                                    </span>
                                </div>
                            </OverlayTrigger>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
                            <form>
                                {/** Head */}
                                <div
                                    className="d-flex flex-column bgi-size-cover bgi-no-repeat rounded-top"
                                    style={{ background: `var(--cs-primary)` }}
                                >
                                    <Tab.Container defaultActiveKey={'Options'}>
                                        <Tab.Content className="tab-content">
                                            <Tab.Pane
                                                eventKey="Options"
                                                id="topbar_switcher_events"
                                            >
                                                <PerfectScrollbar
                                                    options={
                                                        perfectScrollbarOptions
                                                    }
                                                    className="navi navi-hover scroll"
                                                    style={{
                                                        maxHeight: '300px',
                                                        position: 'relative',
                                                    }}
                                                >
                                                    <span className="navi-item cursor-pointer">
                                                        <div className="navi-link">
                                                            <div className="navi-icon mr-4">
                                                                <span className="svg-icon svg-icon-xl svg-icon-">
                                                                    <SVG
                                                                        src={toAbsoluteUrl(
                                                                            '/media/svg/icons/Navigation/Plus.svg',
                                                                        )}
                                                                    />
                                                                </span>
                                                            </div>
                                                            <div className="navi-text">
                                                                <div className="font-weight-bolder">
                                                                    Add New
                                                                </div>
                                                                <div className="opacity-70">
                                                                    <small>
                                                                        Register
                                                                        Rental
                                                                        &amp;
                                                                        More
                                                                    </small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </span>
                                                    {!fetchingData &&
                                                        profiles?.map(
                                                            (profile, i) => {
                                                                return (
                                                                    <span
                                                                        onClick={() =>
                                                                            switchOption(
                                                                                profile,
                                                                                i,
                                                                            )
                                                                        }
                                                                        className={
                                                                            'navi-item ' +
                                                                            (profile.is_validated ===
                                                                                0 ||
                                                                            (host ===
                                                                                'localhost' &&
                                                                                appConfigs.slug !==
                                                                                    (profile.profile ===
                                                                                    'cs-rental'
                                                                                        ? 'rental'
                                                                                        : 'ngo'))
                                                                                ? 'cursor-default opacity-60'
                                                                                : profile._id ===
                                                                                  bank?._id
                                                                                ? 'cursor-default'
                                                                                : 'cursor-pointer')
                                                                        }
                                                                        key={
                                                                            'profile-' +
                                                                            i
                                                                        }
                                                                    >
                                                                        <div
                                                                            className={
                                                                                'navi-link ' +
                                                                                (profile._id ===
                                                                                bank?._id
                                                                                    ? 'active'
                                                                                    : '')
                                                                            }
                                                                        >
                                                                            <div className="navi-icon mr-4">
                                                                                <span className="svg-icon svg-icon-xl svg-icon-dark-50">
                                                                                    <SVG
                                                                                        src={toAbsoluteUrl(
                                                                                            '/media/svg/icons/Communication/Group.svg',
                                                                                        )}
                                                                                    />
                                                                                </span>
                                                                            </div>
                                                                            <div className="navi-text">
                                                                                <div className="font-weight-bolder">
                                                                                    {
                                                                                        profile.name
                                                                                    }
                                                                                </div>
                                                                                <div className="opacity-70">
                                                                                    <small>
                                                                                        <strong>
                                                                                            {profile.profile ===
                                                                                            'cs-rental'
                                                                                                ? 'Rental'
                                                                                                : 'NGO'}
                                                                                        </strong>

                                                                                        ,{' '}
                                                                                        {profile
                                                                                            .role
                                                                                            ?.name ||
                                                                                            ''}
                                                                                    </small>
                                                                                    {profile.is_validated ===
                                                                                        0 && (
                                                                                        <small className="d-block text-danger">
                                                                                            Pending
                                                                                            verification
                                                                                        </small>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            {selected ===
                                                                                i && (
                                                                                <div className="ml-4">
                                                                                    <CircularProgress
                                                                                        variant="indeterminate"
                                                                                        disableShrink
                                                                                        size={
                                                                                            16
                                                                                        }
                                                                                        thickness={
                                                                                            4
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                            {profile._id ===
                                                                                bank?._id && (
                                                                                <div className="navi-icon ml-4">
                                                                                    <span className="svg-icon svg-icon-xl svg-icon-primary">
                                                                                        <SVG
                                                                                            src={toAbsoluteUrl(
                                                                                                '/media/svg/icons/Navigation/Check.svg',
                                                                                            )}
                                                                                        />
                                                                                    </span>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </span>
                                                                );
                                                            },
                                                        )}
                                                </PerfectScrollbar>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Tab.Container>
                                </div>
                            </form>
                        </Dropdown.Menu>
                    </Dropdown>
                )}
        </>
    );
}
