import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { getUserByToken } from './authCrud';
import { appConfigs } from '../../../../_chamasoft/_helpers';

export const actionTypes = {
    Login: '[Login] Action',
    Logout: '[Logout] Action',
    Register: '[Register] Action',
    UserRequested: '[Request User] Action',
    SessionExpired: '[Session Expired] Action',
    UserLoaded: '[Load User] Auth API',
    SetToken: '[Update Token] Auth API',
    SetUser: '[Set User] Action',
};

const initialAuthState = {
    user: undefined,
    authToken: undefined,
    sessionExpired: false,
};

export const reducer = persistReducer(
    { storage, key: appConfigs.auth_key, whitelist: ['user', 'authToken'] },
    (state = initialAuthState, action) => {
        switch (action.type) {
            case actionTypes.Login: {
                const { authToken } = action.payload;
                return { authToken };
            }

            case actionTypes.SetToken: {
                const { authToken } = action.payload;
                return { ...state, authToken };
            }

            case actionTypes.Register: {
                const { authToken } = action.payload;
                return { authToken, user: undefined };
            }

            case actionTypes.Logout: {
                return { authToken: undefined, user: undefined };
            }

            case actionTypes.UserLoaded: {
                const { user } = action.payload;
                return { ...state, user };
            }

            case actionTypes.SetUser: {
                const { user } = action.payload;
                return { ...state, user };
            }

            case actionTypes.SessionExpired: {
                const { sessionExpired } = action.payload;
                return { ...state, sessionExpired };
            }

            default:
                return state;
        }
    },
);

export const actions = {
    sessionExpired: sessionExpired => ({
        type: actionTypes.SessionExpired,
        payload: { sessionExpired },
    }),
    login: authToken => ({ type: actionTypes.Login, payload: { authToken } }),
    setToken: authToken => ({
        type: actionTypes.SetToken,
        payload: { authToken },
    }),
    register: authToken => ({
        type: actionTypes.Register,
        payload: { authToken },
    }),
    logout: () => ({ type: actionTypes.Logout }),
    requestUser: user => ({
        type: actionTypes.UserRequested,
        payload: { user },
    }),
    fulfillUser: user => ({ type: actionTypes.UserLoaded, payload: { user } }),
    setUser: user => ({ type: actionTypes.SetUser, payload: { user } }),
};

export function* saga() {
    yield takeLatest(actionTypes.Login, function* loginSaga() {
        yield put(actions.requestUser());
    });

    yield takeLatest(actionTypes.Register, function* registerSaga() {
        yield put(actions.requestUser());
    });
    yield takeLatest(actionTypes.Register, function* registerSaga() {
        yield put(actions.setUser());
    });

    yield takeLatest(actionTypes.UserRequested, function* userRequested() {
        const { data: user } = yield getUserByToken();
        yield put(actions.fulfillUser(user));
    });
}
