import { createSlice } from '@reduxjs/toolkit';

const initialFinancialsState = {
    listLoading: false, // for list call type
    actionsLoading: false, // for action call type
    totalCount: 0,
    allFinancial: [],
    lastError: null,
};
export const callTypes = {
    list: 'list',
    action: 'action',
};

export const financialsSlice = createSlice({
    name: 'financials',
    initialState: initialFinancialsState,
    reducers: {
        // in case of an error
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        // start a call, setting loading states
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        // fetchMyFinancials
        myFinancialsFetched: (state, action) => {
            const { totalCount, allFinancial } = action.payload;
            state.listLoading = false;
            state.actionsLoading = false;
            state.error = null;
            state.allFinancial = allFinancial;
            state.totalCount = totalCount;
        },
        // fetchFinancial
        financialFetched: (state, action) => {
            const { financialForView } = action.payload;
            state.actionsLoading = false;
            state.financialForView = financialForView;
            state.error = null;
        },
    },
});
