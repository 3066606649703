import React, { useEffect, useMemo, useState } from 'react';
import objectPath from 'object-path';
import { useHtmlClassService } from '../../_core/MetronicLayout';
import { Topbar } from './Topbar';
import { HeaderMenuWrapper } from './header-menu/HeaderMenuWrapper';
import { AnimateLoading } from '../../../_partials/controls';
import { shallowEqual, useSelector } from 'react-redux';
import ContentLoader from 'react-content-loader'; // Create skeleton here: https://skeletonreact.com/
import LogoutDialog from '../../../_helpers/LogoutDialog';
// import * as actions from "../../../../app/modules/Auth/_redux/authRedux";
import { Badge } from 'react-bootstrap';

export function Header() {
    const uiService = useHtmlClassService();
    const [role, setRole] = useState(undefined);

    const { fetchingDataStatus, bank, sessionExpired, user } = useSelector(
        state => ({
            fetchingDataStatus: state.systemProfile.actionsLoading,
            bank: state.systemProfile.data,
            sessionExpired: state.auth.sessionExpired,
            user: state.auth.user,
        }),
        shallowEqual,
    );

    useEffect(() => {
        let _r = '';
        if (user?.profiles?.active?.is_owner === 1) _r = 'Admin';
        else if (user?.profiles?.active?.type === 'agent') _r = 'Agent';
        else if (user?.profiles?.active?.type === 'tenant') _r = 'Tenant';
        else _r = 'User';
        setRole(_r);
    }, [user]);

    const renderFetchData = () => {
        return fetchingDataStatus ? (
            <div className="d-block" style={{ overflow: 'hidden' }}>
                <ContentLoader
                    speed={2}
                    width={180}
                    height={37}
                    viewBox="0 0 180 37"
                    backgroundColor="#ededed"
                    foregroundColor="#dbdbdb"
                >
                    {/* <rect x="0" y="0" rx="3" ry="3" width="30" height="30" />
          <rect x="40" y="0" rx="3" ry="3" width="140" height="16" />
          <rect x="40" y="21" rx="2" ry="2" width="90" height="9" /> */}
                    <rect x="0" y="0" rx="3" ry="3" width="140" height="16" />
                    <rect x="0" y="21" rx="2" ry="2" width="90" height="9" />
                </ContentLoader>
            </div>
        ) : (
            <></>
        );
    };

    const layoutProps = useMemo(() => {
        return {
            headerClasses: uiService.getClasses('header', true),
            headerAttributes: uiService.getAttributes('header'),
            headerContainerClasses: uiService.getClasses(
                'header_container',
                true,
            ),
            menuHeaderDisplay: objectPath.get(
                uiService.config,
                'header.menu.self.display',
            ),
        };
    }, [uiService]);

    return (
        <>
            {/*begin::Header*/}
            <div
                className={`header ${layoutProps.headerClasses}`}
                id="kt_header"
                {...layoutProps.headerAttributes}
            >
                {sessionExpired && (
                    <LogoutDialog firstName={user?.first_name} />
                )}
                {/*begin::Container*/}
                <div
                    className={` ${layoutProps.headerContainerClasses} d-flex align-items-stretch justify-content-between`}
                >
                    <AnimateLoading />
                    {/*begin::Header Menu Wrapper*/}
                    {layoutProps.menuHeaderDisplay && <HeaderMenuWrapper />}
                    {!layoutProps.menuHeaderDisplay && <div />}
                    {/*end::Header Menu Wrapper*/}

                    <div
                        className="header-menu-wrapper header-menu-wrapper-left d-none d-lg-block"
                        style={{ minWidth: '320px' }}
                    >
                        <div className="py-4">
                            {renderFetchData()}
                            <div
                                className={
                                    fetchingDataStatus
                                        ? 'd-none'
                                        : 'd-inline-block'
                                }
                            >
                                <div
                                    className="font-weight-bolder font-size-h6 text-dark-50- mb-0 float-left"
                                    style={{
                                        color: 'var(--cs-secondary)',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        maxWidth: '200px',
                                        width: 'auto',
                                    }}
                                >
                                    {bank?.name || 'Unknown Institution'}
                                </div>
                                {role && (
                                    <div className="d-inline-block">
                                        &nbsp;&nbsp;
                                        <Badge variant="primary">{role}</Badge>
                                    </div>
                                )}
                                <small
                                    className="d-block text-dark-50- opacity-70"
                                    style={{ color: 'var(--cs-secondary)' }}
                                >
                                    {bank?.website || 'Website not set'}
                                </small>
                            </div>
                        </div>
                    </div>

                    {/*begin::Topbar*/}
                    <Topbar />
                    {/*end::Topbar*/}
                </div>
                {/*end::Container*/}
            </div>
            {/*end::Header*/}
        </>
    );
}
