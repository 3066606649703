import React, { Suspense, lazy, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Redirect, Switch, Route } from 'react-router-dom';
import { LayoutSplashScreen, ContentRoute } from '../_chamasoft/layout';
import { hex2Rgba, rgba2hex } from '../_chamasoft/_helpers';
import * as setupActions from './modules/Setup/_redux/setupActions';
// import * as utils from "../_chamasoft/_helpers/LocalStorageHelpers";
import * as auth from './modules/Auth/_redux/authRedux';
import { TourProvider } from '@reactour/tour';

const Dashboard = lazy(() => import('./modules/Dashboard/Dashboard'));
const SetupPage = lazy(() => import('./modules/Setup/Setup'));
const Onboard = lazy(() => import('./modules/Onboard/Onboard'));
const AgentsPage = lazy(() => import('./modules/Agents/Agents'));
const TenantsPage = lazy(() => import('./modules/Tenants/Tenants'));
const PropertiesPage = lazy(() => import('./modules/Properties/Properties'));
const TicketsPage = lazy(() => import('./modules/Tickets/Tickets'));
const ExpensesPage = lazy(() => import('./modules/Expenses/Expenses'));
const ProfilePage = lazy(() => import('./modules/UserProfile/UserProfilePage'));
const SettingsPage = lazy(() => import('./modules/Settings/SettingsPage'));
const ReportsPage = lazy(() => import('./modules/Reports/Reports'));
const InvoicesPage = lazy(() => import('./modules/Invoices/Invoices'));
const FinancialsPage = lazy(() => import('./modules/Financials/Financials'));
const ECommercePage = lazy(() =>
    import('./modules/ECommerce/pages/eCommercePage'),
);
const steps = [
    {
        selector: '.first-step',
        content: (
            <>
                <p>
                    <strong>Property Summary Pane</strong>
                </p>
                <p>
                    This panel displays the summary of the property. It shows
                    the number of properties you own as well as the number of
                    tenants, units, and vacant units.
                </p>
            </>
        ),
    },
    {
        selector: '.second-step',
        content: (
            <>
                <p>
                    <strong>Accounting Panel</strong>
                </p>
                <p>
                    This panel displays revenues generated vs the expenses over
                    monthly periods
                </p>
            </>
        ),
    },
    {
        selector: '.third-step',

        content: (
            <>
                <p>
                    <strong>Payment Collection Panel</strong>
                </p>
                <p>
                    This panel displays the percentage of rent collected against
                    all the units
                </p>
            </>
        ),
    },
    {
        selector: '.fourth-step',
        content: (
            <>
                <p>
                    <strong>Billing Panel</strong>
                </p>
                <p>
                    This panel displays the number of invoices generated and the
                    number of invoices paid.
                </p>
            </>
        ),
    },
    // {
    //     selector: '.fifth-step',
    //     content: (
    //         <>
    //             <p>
    //                 <strong>Properties Panel</strong>
    //             </p>
    //             <p>
    //                 This panel displays a snapshot of properties added to this
    //                 account
    //             </p>
    //         </>
    //     ),
    // },
    // ...
];

export default function BasePage() {
    const dispatch = useDispatch();
    const { accessToken } = useSelector(
        state => ({
            accessToken: state.auth.authToken,
        }),
        shallowEqual,
    );
    const { user } = useSelector(
        state => ({
            user: state.auth.user,
        }),
        shallowEqual,
    );
    useEffect(() => {
        dispatch(setupActions.fetchSystemProfile());
        dispatch(setupActions.fetchSystemTheme());
        dispatch(setupActions.fetchProfiles(accessToken));
        dispatch(setupActions.fetchSystemSettings());
        dispatch(setupActions.fetchSystemServices());

        dispatch(auth.actions.setUser(user));
    }, [dispatch, accessToken, user]);

    const { fetchingData, theme, fetchingSettings, settings } = useSelector(
        state => ({
            fetchingData: state.systemTheme.actionsLoading,
            theme: state.systemTheme.data,
            fetchingSettings: state.systemSettings.actionsLoading,
            settings: state.systemSettings.settings,
        }),
        shallowEqual,
    );

    useEffect(() => {
        // console.log('Pantone: ', theme?.pantone);
        // if (theme?.pantone) {
        //   utils.setStorage(
        //     "pantone",
        //     JSON.stringify(theme?.pantone),
        //     (24 * 60 * 60 * 365)
        //   );
        // }
        if (
            theme?.pantone?.color1 &&
            theme?.pantone?.color1 !== '' &&
            theme?.pantone?.color1 !== '#undefined' &&
            !fetchingSettings &&
            settings?.disableCustomBranding !== 'true'
        ) {
            document.documentElement.style.setProperty(
                '--cs-primary',
                hex2Rgba(theme?.pantone?.color1, 1),
            );
            document.documentElement.style.setProperty(
                '--cs-primary-bg',
                hex2Rgba(theme?.pantone?.color1, 0.08),
            );
            document.documentElement.style.setProperty(
                '--cs-menu-bg',
                hex2Rgba(theme?.pantone?.color1, 0.18),
            );
            document.documentElement.style.setProperty(
                '--cs-submenu-bg',
                hex2Rgba(theme?.pantone?.color1, 0.08),
            );
            document.documentElement.style.setProperty(
                '--cs-submenu-bg-hover',
                hex2Rgba(theme?.pantone?.color1, 0.12),
            );
        } else {
            document.documentElement.style.setProperty(
                '--cs-primary',
                hex2Rgba('#3699ff', 1),
            );
            document.documentElement.style.setProperty(
                '--cs-primary-bg',
                hex2Rgba('#eaf2f5', 0.08),
            );
            document.documentElement.style.setProperty(
                '--cs-menu-bg',
                hex2Rgba('#eaeef3', 0.18),
            );
            document.documentElement.style.setProperty(
                '--cs-submenu-bg',
                hex2Rgba('#eaeef3', 0.08),
            );
            document.documentElement.style.setProperty(
                '--cs-submenu-bg-hover',
                hex2Rgba('#f3f6f9', 0.12),
            );
        }

        if (
            theme?.pantone?.color2 &&
            theme?.pantone?.color2 !== '' &&
            theme?.pantone?.color2 !== '#undefined' &&
            !fetchingSettings &&
            settings?.disableCustomBranding !== 'true'
        ) {
            document.documentElement.style.setProperty(
                '--cs-secondary',
                hex2Rgba(theme?.pantone?.color2, 1),
            );
            document.documentElement.style.setProperty(
                '--cs-secondary-lighter',
                rgba2hex(hex2Rgba(theme?.pantone?.color2, 0.6)),
            );
        } else {
            document.documentElement.style.setProperty(
                '--cs-secondary',
                'rgba(45, 87, 106, 1)',
            );
            document.documentElement.style.setProperty(
                '--cs-secondary-lighter',
                rgba2hex('rgba(76, 152, 187, 1)'),
            );
        }
        // console.log(window.getComputedStyle(document.documentElement).getPropertyValue("--cs-secondary"));
        // console.log(window.getComputedStyle(document.documentElement).getPropertyValue("--cs-secondary-lighter"));
    }, [theme, fetchingSettings, settings]);

    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            {fetchingData && <LayoutSplashScreen />}
            <TourProvider steps={steps}>
                {!fetchingData && (
                    <Switch>
                        {
                            /* Redirect from root URL to /dashboard. */
                            <Redirect exact from="/" to="/dashboard" />
                        }
                        <Route path="/setup" component={SetupPage} />
                        <ContentRoute path="/dashboard" component={Dashboard} />
                        <ContentRoute path="/onboard" component={Onboard} />
                        <ContentRoute
                            path="/properties"
                            component={PropertiesPage}
                        />
                        <ContentRoute path="/agents" component={AgentsPage} />
                        <ContentRoute path="/tenants" component={TenantsPage} />
                        <ContentRoute path="/tickets" component={TicketsPage} />
                        <ContentRoute
                            path="/expenses"
                            component={ExpensesPage}
                        />
                        <ContentRoute path="/profile" component={ProfilePage} />
                        <ContentRoute path="/reports" component={ReportsPage} />
                        <ContentRoute
                            path="/tables"
                            component={ECommercePage}
                        />
                        <ContentRoute
                            path="/settings"
                            component={SettingsPage}
                        />
                        <ContentRoute
                            path="/invoices"
                            component={InvoicesPage}
                        />
                        <ContentRoute
                            path="/financials"
                            component={FinancialsPage}
                        />
                        <Redirect to="/dashboard" />
                        {/* <Redirect to="error/err" /> */}
                    </Switch>
                )}
            </TourProvider>
        </Suspense>
    );
}
