import React from 'react';
import { appConfigs, toAbsoluteUrl } from '../../../_chamasoft/_helpers';
import '../../../_chamasoft/_assets/sass/pages/error/error-5.scss';
import { Link } from 'react-router-dom';

export function ErrPage() {
    document.title = 'Error — ' + appConfigs.app_name;

    return (
        <div className="d-flex flex-column flex-root">
            <div
                className="error error-5 d-flex flex-row-fluid bgi-size-cover bgi-position-center"
                style={{
                    backgroundImage: `url(${toAbsoluteUrl(
                        '/media/error/bg5.jpg',
                    )})`,
                }}
            >
                <div className="container d-flex flex-row-fluid flex-column justify-content-md-center p-12">
                    <h1 className="error-title font-weight-boldest text-info mt-10 mt-md-0 mb-12">
                        Oops!
                    </h1>
                    <p className="font-weight-boldest display-4">
                        Something went wrong here.
                    </p>
                    <p className="font-size-h3 opacity-80">
                        We're working on it and we'll get it fixed
                        <br />
                        as soon possible.
                    </p>
                    <p>
                        <Link
                            to="/"
                            className="font-weight-bold btn btn-light-primary btn-wide px-5"
                        >
                            Okay, I get it
                        </Link>
                    </p>
                </div>
            </div>
        </div>
    );
}
