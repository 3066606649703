import { createSlice } from '@reduxjs/toolkit';

const initialPropertiesState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    propertyForView: undefined,
    lastError: null,
};

export const callTypes = {
    list: 'list',
    action: 'action',
};

export const propertiesSlice = createSlice({
    name: 'properties',
    initialState: initialPropertiesState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        // getPropertyById
        propertyFetched: (state, action) => {
            state.actionsLoading = false;
            state.propertyForView = action.payload.propertyForView;
            state.error = null;
        },
        // findProperties
        propertiesFetched: (state, action) => {
            const { totalCount, entities } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = entities;
            state.totalCount = totalCount;
        },
        // createProperty
        propertyCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.entities.push(action.payload.property);
        },
        // updateProperty
        propertyUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.map(entity => {
                if (entity.id === action.payload.property.id) {
                    return action.payload.property;
                }
                return entity;
            });
        },
        // deleteProperty
        propertyDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.filter(
                el => el.id !== action.payload.id,
            );
        },
        // deleteProperties
        propertiesDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.filter(
                el => !action.payload.ids.includes(el.id),
            );
        },
        // propertiesUpdateState
        propertiesStatusUpdated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            const { ids, status } = action.payload;
            state.entities = state.entities.map(entity => {
                if (ids.findIndex(id => id === entity.id) > -1) {
                    entity.status = status;
                }
                return entity;
            });
        },
        // fetchMyProperties
        myPropertiesFetched: (state, action) => {
            const {
                totalCount,
                entities,
                individual_applications,
            } = action.payload;
            state.listLoading = false;
            state.actionsLoading = false;
            state.error = null;
            state.entities = entities;
            state.totalCount = totalCount;
            state.individualApplications = individual_applications;
        },
    },
});
