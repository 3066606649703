import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ErrPage } from './ErrPage';
import { SetupErrPage } from './SetupErrPage';

export default function ErrorsPage() {
    return (
        <Switch>
            <Redirect from="/error" exact={true} to="/error/err" />
            <Route path="/error/setup" component={SetupErrPage} />
            <Route path="/error/err" component={ErrPage} />
            <Redirect to="/error/err" />
        </Switch>
    );
}
