import * as requestFromServer from './setupCrud';
import {
    checkinSlice,
    countiesSlice,
    systemProfileSlice,
    systemServicesSlice,
    systemSettingsSlice,
    systemThemeSlice,
    systemUsersSlice,
} from './setupSlice';
import * as auth from '../../Auth/_redux/authRedux';

export const fetchSystemProfile = queryParams => async dispatch => {
    dispatch(systemProfileSlice.actions.startCall());
    try {
        let response = await requestFromServer.getProfile(queryParams);
        const { data } = response.data;

        // console.log('Show system new Profile', data);

        if (
            response.data.result_code === 2 ||
            response.data.result_code === 7
        ) {
            return dispatch(auth.actions.logout(true));
        }

        return dispatch(
            systemProfileSlice.actions.systemProfileFetched({ data }),
        );
    } catch (error) {
        console.log(error);
        //error.clientMessage = "Can't find data";
        return dispatch(systemProfileSlice.actions.catchError({ error }));
    }
};

export const fetchSystemTheme = queryParams => async dispatch => {
    dispatch(systemThemeSlice.actions.startCall());
    try {
        let response = await requestFromServer.getTheme(queryParams);
        const { system } = response.data;

        if (
            response.data.result_code === 2 ||
            response.data.result_code === 7
        ) {
            return dispatch(auth.actions.logout(true));
        }

        return dispatch(
            systemThemeSlice.actions.systemThemeFetched({ system }),
        );
    } catch (error) {
        console.log(error);
        //error.clientMessage = "Can't find data";
        return dispatch(systemThemeSlice.actions.catchError({ error }));
    }
};

export const fetchSystemServices = accessToken => async dispatch => {
    dispatch(systemServicesSlice.actions.startCall());
    try {
        let response = await requestFromServer.getServices(accessToken);
        const {
            data,
            service_types,
            biodata_options,
            county_options,
        } = response.data;
        return dispatch(
            systemServicesSlice.actions.systemServicesFetched({
                data,
                service_types,
                biodata_options,
                county_options,
            }),
        );
    } catch (error) {
        console.log(error);
        //error.clientMessage = "Can't find data";
        return dispatch(systemServicesSlice.actions.catchError({ error }));
    }
};

export const fetchSystemUsers = queryParams => async dispatch => {
    dispatch(systemUsersSlice.actions.startCall());
    try {
        // let response = await requestFromServer.getUsers(queryParams);
        let [response, response2] = await Promise.all([
            requestFromServer.getUsers(queryParams),
            requestFromServer.getRoles(queryParams),
        ]);
        const { users, roles } = response.data;
        const { data } = response2.data;

        return dispatch(
            // systemUsersSlice.actions.systemUsersFetched({ users, roles }),
            systemUsersSlice.actions.systemUsersFetched({ users, roles, data }),
        );
    } catch (error) {
        console.log(error);
        //error.clientMessage = "Can't find data";
        return dispatch(systemUsersSlice.actions.catchError({ error }));
    }
};

export const fetchProfiles = accessToken => async dispatch => {
    dispatch(checkinSlice.actions.startCall());
    try {
        let response = await requestFromServer.getCheckin(accessToken);
        const { profiles } = response.data;
        return dispatch(checkinSlice.actions.profilesFetched({ profiles }));
    } catch (error) {
        console.log(error);
        //error.clientMessage = "Can't find data";
        return dispatch(checkinSlice.actions.catchError({ error }));
    }
};

export const fetchSystemSettings = queryParams => async dispatch => {
    dispatch(systemSettingsSlice.actions.startCall());
    try {
        let response = await requestFromServer.getSettings(queryParams);
        const { data } = response.data;

        if (
            response.data.result_code === 2 ||
            response.data.result_code === 7
        ) {
            return dispatch(auth.actions.logout(true));
        }

        return dispatch(
            systemSettingsSlice.actions.systemSettingsFetched({ data }),
        );
    } catch (error) {
        console.log(error);
        //error.clientMessage = "Can't find data";
        return dispatch(systemSettingsSlice.actions.catchError({ error }));
    }
};

export const fetchCounties = queryParams => async dispatch => {
    dispatch(countiesSlice.actions.startCall());
    try {
        let response = await requestFromServer.getCounties(queryParams);
        const { data } = response;
        return dispatch(countiesSlice.actions.countiesFetched({ data }));
    } catch (error) {
        console.log(error);
        //error.clientMessage = "Can't find data";
        return dispatch(countiesSlice.actions.catchError({ error }));
    }
};
