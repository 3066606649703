// The config from the environmental variables

export const appConfigs = {
    slug: process.env.REACT_APP_SLUG,
    app_name: process.env.REACT_APP_NAME,
    org_name: process.env.REACT_APP_ORG_NAME,
    org_url: process.env.REACT_APP_ORG_URL,
    api_url: process.env.REACT_APP_API_URL,
    backdating_api_url: process.env.REACT_APP_BACKDATING_API_URL,
    auth_url: process.env.REACT_APP_AUTH_URL,
    auth_api_url: process.env.REACT_APP_AUTH_API_URL,
    auth_key: process.env.REACT_APP_AUTH_KEY,
    uploads_url: process.env.REACT_APP_UPLOADS_URL,
    bank_home_url: process.env.REACT_APP_BANK_HOME_URL,
    ngo_home_url: process.env.REACT_APP_NGO_HOME_URL,
    recaptcha_site_key: '6LfdYeQZAAAAAPijoDtW-p4RLdUZBnc0brmarW5M',
    api_static_assets_url: process.env.REACT_APP_API_STATIC_ASSETS_URL,
};
