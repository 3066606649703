import { createSlice } from '@reduxjs/toolkit';

const initialExpenseCategoriesState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    expenseCategoryForView: undefined,
    lastError: null,
};
export const callTypes = {
    list: 'list',
    action: 'action',
};

export const expenseCategoriesSlice = createSlice({
    name: 'expenseCategories',
    initialState: initialExpenseCategoriesState,
    reducers: {
        // in case of an error
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        // start a call, setting loading states
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        // Find all expenses
        expenseCategoriesFetched: (state, action) => {
            const { totalCount, entities } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = entities;
            state.totalCount = totalCount;
        },
        expenseCategoryFetched: (state, action) => {
            state.actionsLoading = false;
            const { expenseCategoryForView } = action.payload;
            state.expenseCategoryForView = expenseCategoryForView;
            state.error = null;
        },
        // createExpense
        expenseCategoriesCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.entities.push(action.payload.expense);
        },
        // updateExpense
        expenseCategoriesUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.map(entity => {
                if (entity.id === action.payload.expense.id) {
                    return action.payload.expense;
                }
                return entity;
            });
        },
        // deleteExpense
        expenseCategoriesDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.filter(
                el => el.id !== action.payload.id,
            );
        },
        // deleteExpenses
        expensesCategoriesDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.filter(
                el => !action.payload.ids.includes(el.id),
            );
        },
        // expensesUpdateState
        expensesCategoriesStatusUpdated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            const { ids, status } = action.payload;
            state.entities = state.entities.map(entity => {
                if (ids.findIndex(id => id === entity.id) > -1) {
                    entity.status = status;
                }
                return entity;
            });
        },
        // expensesIsHiddenState
        expensesCategoriesIsHiddenUpdated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            const { ids, isHidden } = action.payload;
            state.entities = state.entities.map(entity => {
                if (ids.findIndex(id => id === entity.id) > -1) {
                    entity.isHidden = isHidden;
                }
                return entity;
            });
        },
    },
});
