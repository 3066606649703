import { createSlice } from '@reduxjs/toolkit';

const initialPropertyTypesState = {
    actionsLoading: false,
    types: [],
};

const initialCountriesState = {
    actionsLoading: false,
    countries: [],
};

const initialCurrenciesState = {
    actionsLoading: false,
    currencies: [],
};

const initialGroupInfoUpdateState = {
    actionsLoading: false,
    group: [],
};

const initialMembersInfoState = {
    actionsLoading: false,
    members: [],
};
const initialUnitsState = {
    actionsLoading: false,
    units: [],
    availableUnits: [],
};
const initialTenantsState = {
    actionsLoading: false,
    tenants: [],
};

const initialUnitTypesState = {
    actionsLoading: false,
    unitTypes: [],
    allUnitTypes: [],
};

const initialNewTenantsState = {
    actionsLoading: false,
    tenants: [],
};

const initialGroupContributionsState = {
    actionsLoading: false,
    contributions: [],
    defaults: {},
};

const initialGroupLoansState = {
    actionsLoading: false,
    loans: [],
    defaults: {},
};

const initialGroupAccountsState = {
    actionsLoading: false,
    accounts: [],
    defaults: {},
};

const initialPropertyConfigurationsState = {
    actionsLoading: false,
    propertyConfigurations: [],
};

const initialPropertyTicketsState = {
    actionsLoading: false,
    propertyTickets: [],
};

const initialPropertyUtilitiesState = {
    actionsLoading: false,
    propertyUtilities: [],
    utilityCategories: [],
};

const initialGroupConfirmState = {
    actionsLoading: false,
    defaults: {},
    propertyCurrency: {},
};

const initialBanksState = {
    actionsLoading: false,
    banks: [],
};

const initialBankBranchesState = {
    actionsLoading: false,
    branches: [],
};

export const propertyTypesSlice = createSlice({
    name: 'propertyTypes',
    initialState: initialPropertyTypesState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        propertyTypesFetched: (state, action) => {
            const { propertyTypes } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.types = propertyTypes;
        },
    },
});

export const countriesSlice = createSlice({
    name: 'countries',
    initialState: initialCountriesState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        countriesFetched: (state, action) => {
            const { data } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.countries = data;
        },
    },
});

export const currenciesSlice = createSlice({
    name: 'currencies',
    initialState: initialCurrenciesState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        currenciesFetched: (state, action) => {
            const { data } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.currencies = data;
        },
    },
});

export const groupInfoSlice = createSlice({
    name: 'groupInfo',
    initialState: initialGroupInfoUpdateState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        groupInfoUpdated: (state, action) => {
            const { data } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.group = data;
        },
    },
});

export const membersInfoSlice = createSlice({
    name: 'membersInfo',
    initialState: initialMembersInfoState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        tenantsFetched: (state, action) => {
            const { data } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.members = data;
        },
        tenantsUpdated: (state, action) => {
            const { data } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.members = data;
        },
    },
});

export const unitsSlice = createSlice({
    name: 'units',
    initialState: initialUnitsState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        unitsFetched: (state, action) => {
            const { data } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.units = data;
        },
        unitsUpdated: (state, action) => {
            const { data } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.units = data;
        },
        unitsAvailable: (state, action) => {
            const { data } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.availableUnits = data;
        },
    },
});
export const tenantsSlice = createSlice({
    name: 'tenants',
    initialState: initialTenantsState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        tenantsFetched: (state, action) => {
            const { data } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.tenants = data;
        },
        tenantsUpdated: (state, action) => {
            const { data } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.tenants = data;
        },
    },
});

export const newTenantsSlice = createSlice({
    name: 'newTenants',
    initialState: initialNewTenantsState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        tenantsFetched: (state, action) => {
            const { data } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.tenants = data;
        },
        // unitTypesUpdated: (state, action) => {
        //     const { data } = action.payload;
        //     state.actionsLoading = false;
        //     state.error = null;
        //     state.unitTypes = data;
        // },
    },
});

export const unitTypesSlice = createSlice({
    name: 'unitTypes',
    initialState: initialUnitTypesState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        unitTypesFetched: (state, action) => {
            const { data } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.unitTypes = data;
        },
        unitTypesUpdated: (state, action) => {
            const { data } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.unitTypes = data;
        },
        allUnitTypesFetched: (state, action) => {
            const { data } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.allUnitTypes = data;
        },
    },
});

export const groupContributionsSlice = createSlice({
    name: 'groupContributions',
    initialState: initialGroupContributionsState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        groupContributionsDefaultsFetched: (state, action) => {
            const { data } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.defaults = data;
        },
        groupContributionsFetched: (state, action) => {
            const { data } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.contributions = data;
        },
        groupContributionsUpdated: (state, action) => {
            const { data } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.contributions = data;
        },
    },
});

export const groupLoansSlice = createSlice({
    name: 'groupLoans',
    initialState: initialGroupLoansState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        groupLoansDefaultsFetched: (state, action) => {
            const { data } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.defaults = data;
        },
        groupLoansFetched: (state, action) => {
            const { data } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.loans = data;
        },
        groupLoansUpdated: (state, action) => {
            const { data } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.loans = data;
        },
    },
});

export const groupAccountsSlice = createSlice({
    name: 'groupAccounts',
    initialState: initialGroupAccountsState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        groupAccountsDefaultsFetched: (state, action) => {
            const { data } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.defaults = data;
        },
        groupAccountsFetched: (state, action) => {
            const { data } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.accounts = data;
        },
        groupAccountsUpdated: (state, action) => {
            const { data } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.accounts = data;
        },
    },
});

export const propertyConfigurationSlice = createSlice({
    name: 'propertyConfigurations',
    initialState: initialPropertyConfigurationsState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        propertyConfigurationsFetched: (state, action) => {
            const { data } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.propertyConfigurations = data;
        },
    },
});

export const propertyUtilitiesSlice = createSlice({
    name: 'propertyUtilities',
    initialState: initialPropertyUtilitiesState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        propertyUtilitiesFetched: (state, action) => {
            const { data } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.propertyUtilities = data;
        },
        utilityCategoriesFetched: (state, action) => {
            const { data } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.utilityCategories = data;
        },
    },
});

export const propertyTicketsSlice = createSlice({
    name: 'propertyTickets',
    initialState: initialPropertyTicketsState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        propertyTicketsFetched: (state, action) => {
            const { data } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.propertyTickets = data;
        },
    },
});

export const groupConfirmSlice = createSlice({
    name: 'groupConfirm',
    initialState: initialGroupConfirmState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        groupSummaryDefaultsFetched: (state, action) => {
            const { data } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.defaults = data;
        },
        propertyCurrencyFetched: (state, action) => {
            const { data } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.propertyCurrency = data;
        },
    },
});

export const banksSlice = createSlice({
    name: 'banks',
    initialState: initialBanksState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        banksFetched: (state, action) => {
            const { data } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.banks = data;
        },
    },
});

export const bankBranchesSlice = createSlice({
    name: 'bankBranches',
    initialState: initialBankBranchesState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        bankBranchesFetched: (state, action) => {
            const { data } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.branches = data;
        },
    },
});
