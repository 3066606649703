import axios from 'axios';
import { appConfigs } from '../../../../_chamasoft/_helpers';

export async function getProfile() {
    try {
        let result = await axios({
            method: 'POST',
            url:
                appConfigs.auth_api_url +
                (appConfigs.slug === 'rentals'
                    ? '/rentals/fetch-rental'
                    : '/ngos/fetch-ngo'),
        });
        return result;
    } catch (error) {
        throw new Error(error).message;
    }
}

export async function updateProfile(phone, email, website, address) {
    try {
        let result = await axios({
            method: 'POST',
            url:
                appConfigs.auth_api_url +
                (appConfigs.slug === 'rentals'
                    ? '/rentals/update-rental'
                    : '/ngos/update-ngo'),
            data: {
                phone,
                email,
                website,
                address,
            },
        });
        return result;
    } catch (error) {
        throw new Error(error).message;
    }
}

export async function getTheme() {
    try {
        let result = await axios({
            method: 'POST',
            url:
                appConfigs.auth_api_url +
                (appConfigs.slug === 'rentals'
                    ? '/rentals/get-rental-theme'
                    : '/ngos/get-ngo-theme'),
        });
        return result;
    } catch (error) {
        throw new Error(error).message;
    }
}

export async function updateTheme(avatar, pantone) {
    try {
        let result = await axios({
            method: 'POST',
            url:
                appConfigs.auth_api_url +
                (appConfigs.slug === 'rentals'
                    ? '/rentals/update-rental-theme'
                    : '/ngos/update-ngo-theme'),
            data: {
                avatar,
                pantone,
            },
        });
        return result;
    } catch (error) {
        throw new Error(error).message;
    }
}

export async function getServices(accessToken) {
    try {
        let response = await axios({
            method: 'GET',
            url:
                appConfigs.api_url +
                (appConfigs.slug === 'rentals'
                    ? '/view-property-types'
                    : 'ngo-services/get-ngo-services'),
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
        return response;
    } catch (error) {
        throw new Error(error).message;
    }
}

export async function getSettings() {
    try {
        let result = await axios({
            method: 'POST',
            url: `${appConfigs.auth_api_url}/get-settings`,
        });
        return result;
    } catch (error) {
        throw new Error(error).message;
    }
}

export async function updateSettings(settings) {
    try {
        let result = await axios({
            method: 'POST',
            url: appConfigs.auth_api_url + '/update-settings',
            data: {
                settings,
            },
        });
        return result;
    } catch (error) {
        throw new Error(error).message;
    }
}

export async function updateServices(accessToken, queryParams) {
    try {
        let result = await axios({
            method: 'POST',
            url:
                appConfigs.api_url +
                (appConfigs.slug === 'rentals'
                    ? '/update-property-types'
                    : 'ngo-services/update-ngo-services'),
            data: {
                groupEnableBiodata: parseInt(queryParams.biodata),
                groupEnableLocation: parseInt(queryParams.location),
                isPartnerEnabled: parseInt(queryParams.partner),
                services: queryParams.selGroups,
                biodata: queryParams.biodataSelected,
            },
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
        return result;
    } catch (error) {
        throw new Error(error).message;
    }
}

export async function getUsers() {
    try {
        let result = await axios({
            method: 'POST',
            url: `${appConfigs.auth_api_url}/users/get-users`,
        });
        return result;
    } catch (error) {
        throw new Error(error).message;
    }
}

// fetch roles from Rental API
export async function getRoles() {
    try {
        let result = await axios({
            method: 'POST',
            url: `${appConfigs.api_url}/view-roles`,
        });
        return result;
    } catch (error) {
        throw new Error(error).message;
    }
}

export async function updateUsers(fullname, email, phone, role_id, type) {
    try {
        let result = await axios({
            method: 'POST',
            url: appConfigs.auth_api_url + '/users/invite',
            data: {
                fullname,
                email,
                phone,
                role_id,
                type,
            },
        });
        return result;
    } catch (error) {
        throw new Error(error).message;
    }
}

export async function removeInstitutionUser(user) {
    try {
        let result = await axios({
            method: 'POST',
            url: appConfigs.auth_api_url + '/users/remove-user',
            data: {
                user,
            },
        });
        return result;
    } catch (error) {
        throw new Error(error).message;
    }
}

export async function updateProgress(accessToken, progress) {
    try {
        let result = await axios({
            method: 'POST',
            url: appConfigs.auth_api_url + '/users/update-progress',
            data: {
                progress,
            },
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });

        //systemProfileSlice.actions.systemProfileFetched({ data });

        return result;
    } catch (error) {
        throw new Error(error).message;
    }
}

export async function getCheckin(accessToken) {
    try {
        let result = await axios({
            method: 'POST',
            url: `${appConfigs.auth_api_url}/users/checkin-data`,
            data: {
                accessToken,
            },
        });
        return result;
    } catch (error) {
        throw new Error(error).message;
    }
}

export async function getCounties() {
    try {
        let result = await axios({
            method: 'POST',
            url: `${appConfigs.auth_api_url}/get-counties`,
        });
        return result;
    } catch (error) {
        throw new Error(error).message;
    }
}

export async function sendUserToRental() {
    try {
        let result = await axios({
            method: 'POST',
            url: `${appConfigs.api_url}/user/create`,
        });
        return result;
    } catch (error) {
        throw new Error(error).message;
    }
}
