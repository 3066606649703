import { createSlice } from '@reduxjs/toolkit';

const initialTicketsState = {
    listLoading: false, // for list call type
    actionsLoading: false, // for action call type
    totalCount: 0,
    entities: null,
    ticketCategories: [],
    ticketForView: undefined,
    lastError: null,
};
export const callTypes = {
    list: 'list',
    action: 'action',
};

export const ticketsSlice = createSlice({
    name: 'tickets',
    initialState: initialTicketsState,
    reducers: {
        // in case of an error
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        // start a call, setting loading states
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        // getTicketById
        ticketFetched: (state, action) => {
            state.actionsLoading = false;
            const { ticketForView } = action.payload;
            state.ticketForView = ticketForView;
            state.error = null;
        },
        // findTickets
        ticketsFetched: (state, action) => {
            const { totalCount, entities } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = entities;
            state.totalCount = totalCount;
        },
        // createTicket
        ticketCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.entities.push(action.payload.ticket);
        },
        // updateTicket
        ticketUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.map(entity => {
                if (entity.id === action.payload.ticket.id) {
                    return action.payload.ticket;
                }
                return entity;
            });
        },
        // deleteTicket
        ticketDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.filter(
                el => el.id !== action.payload.id,
            );
        },
        // deleteTickets
        ticketsDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.filter(
                el => !action.payload.ids.includes(el.id),
            );
        },
        // ticketsUpdateState
        ticketsStatusUpdated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            const { ids, status } = action.payload;
            state.entities = state.entities.map(entity => {
                if (ids.findIndex(id => id === entity.id) > -1) {
                    entity.status = status;
                }
                return entity;
            });
        },
        // fetchMyTickets
        myTicketsFetched: (state, action) => {
            const {
                totalCount,
                entities,
                individual_applications,
            } = action.payload;
            state.listLoading = false;
            state.actionsLoading = false;
            state.error = null;
            state.entities = entities;
            state.totalCount = totalCount;
            state.individualApplications = individual_applications;
        },
        ticketCategoriesFetched: (state, action) => {
            const { totalCount, ticketCategories } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.ticketCategories = ticketCategories;
            state.totalCount = totalCount;
        },
    },
});
