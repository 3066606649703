import { createSlice } from '@reduxjs/toolkit';

const initialExpensesState = {
    listLoading: false, // for list call type
    actionsLoading: false, // for action call type
    totalCount: 0,
    totalAmountExpense: 0,
    entities: null,
    expenseForView: undefined,
    lastError: null,
};
export const callTypes = {
    list: 'list',
    action: 'action',
};

export const expensesSlice = createSlice({
    name: 'expenses',
    initialState: initialExpensesState,
    reducers: {
        // in case of an error
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        // start a call, setting loading states
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        // Find all expenses
        expensesFetched: (state, action) => {
            const { totalCount, entities, totalAmountExpense } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = entities;
            state.totalCount = totalCount;
            state.totalAmountExpense = totalAmountExpense;
        },
        // Find one expenses
        expenseOneFetched: (state, action) => {
            state.actionsLoading = false;
            const { expenseForView } = action.payload;
            state.expenseForView = expenseForView;
            state.error = null;
        },
        // createExpense
        expenseCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.entities.push(action.payload.expense);
        },
        // updateExpense
        expenseUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.map(entity => {
                if (entity.id === action.payload.expense.id) {
                    return action.payload.expense;
                }
                return entity;
            });
        },
        // fetchMyExpenses
        myExpensesFetched: (state, action) => {
            const {
                totalCount,
                entities,
                individual_applications,
            } = action.payload;
            state.listLoading = false;
            state.actionsLoading = false;
            state.error = null;
            state.entities = entities;
            state.totalCount = totalCount;
            state.individualApplications = individual_applications;
        },
        expenseCategoriesFetched: (state, action) => {
            const { totalCount, expenseCategories } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.expenseCategories = expenseCategories;
            state.totalCount = totalCount;
        },
        // deleteExpense
        expenseCategoriesDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.filter(
                el => el.id !== action.payload.id,
            );
        },
        // deleteExpenses
        expensesCategoriesDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.filter(
                el => !action.payload.ids.includes(el.id),
            );
        },
        // expensesUpdateState
        expensesCategoriesStatusUpdated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            const { ids, status } = action.payload;
            state.entities = state.entities.map(entity => {
                if (ids.findIndex(id => id === entity.id) > -1) {
                    entity.status = status;
                }
                return entity;
            });
        },
        // expensesIsHiddenState
        expensesCategoriesIsHiddenUpdated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            const { ids, isHidden } = action.payload;
            state.entities = state.entities.map(entity => {
                if (ids.findIndex(id => id === entity.id) > -1) {
                    entity.isHidden = isHidden;
                }
                return entity;
            });
        },
    },
});
