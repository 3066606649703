import { createSlice } from '@reduxjs/toolkit';

const initialReportState = {
    dataLoading: false,
    data: [],
    error: '',
};

export const tenantsReportSlice = createSlice({
    name: 'tenantsReport',
    initialState: initialReportState,
    reducers: {
        catchError: (state, action) => {
            state.error = `error: ${action.payload.error}`;
            state.dataLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.dataLoading = true;
        },
        reportFetched: (state, action) => {
            const { data } = action.payload;
            state.dataLoading = false;
            state.error = null;
            state.data = data;
        },
    },
});

export const propertiesReportSlice = createSlice({
    name: 'propertiesReport',
    initialState: initialReportState,
    reducers: {
        catchError: (state, action) => {
            state.error = `error: ${action.payload.error}`;
            state.dataLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.dataLoading = true;
        },
        reportFetched: (state, action) => {
            const { data } = action.payload;
            state.dataLoading = false;
            state.error = null;
            state.data = data;
        },
    },
});

export const incomesExpensesReportSlice = createSlice({
    name: 'incomesExpensesReport',
    initialState: initialReportState,
    reducers: {
        catchError: (state, action) => {
            state.error = `error: ${action.payload.error}`;
            state.dataLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.dataLoading = true;
        },
        reportFetched: (state, action) => {
            const { data } = action.payload;
            state.dataLoading = false;
            state.error = null;
            state.data = data;
        },
    },
});
