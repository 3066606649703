import { createSlice } from '@reduxjs/toolkit';

const initialPropertyState = {
    actionsLoading: false,
    count: '',
    propertyForView: {},
};

const initialTicketState = {
    actionsLoading: false,
    count: '',
    tickets: [],
};

const initialUnitState = {
    actionsLoading: false,
    count: '',
    units: [],
};

const initialAllUnitState = {
    actionsLoading: false,
    allUnit: {},
    allUnitCount: '',
};

const initialEmployerState = {
    actionsLoading: false,
    count: '',
};

const initialTenantState = {
    actionsLoading: false,
    count: '',
};

const initialQuickStatsState = {
    actionsLoading: false,
    currency: '',
    amountTransacted: {
        formatted: '',
        actual: 0,
    },
    bankAccounts: {
        formatted: '',
        actual: 0,
    },
    cashAtHandAccounts: {
        formatted: '',
        actual: 0,
    },
    transactionsCount: {
        formatted: '',
        actual: 0,
    },
};

const initialPropertyDataByLandlordState = {
    actionsLoading: false,
    landlordDataList: {
        property_data: [],
        unit_data: [],
        tenant_data: [],
    },
};

const initialRecentActivityState = {
    actionsLoading: false,
    signups: [],
};

const depositsVsWithdrawalsState = {
    actionsLoading: false,
    data: [],
};

const initialLoansStatsState = {
    actionsLoading: false,
    currency: '',
    totalLoaned: {
        formatted: '',
        actual: 0,
    },
    amountRepayed: {
        formatted: '',
        actual: 0,
    },
    amountDefaulted: {
        formatted: '',
        actual: 0,
    },
};

const initialCommunicationsState = {
    actionsLoading: false,
    totalSms: 0,
    totalEmail: 0,
    data: [],
};

const initialPropertiesSummaryState = {
    actionsLoading: false,
    AllProperties: 0,
    ActiveToday: 0,
    data: [],
};

const initialMeetingsState = {
    actionsLoading: false,
    meetings: [],
};

const initialIncomeState = {
    actionsLoading: false,
    incomeList: {},
    incomeTotal: '',
};

const initialExpensesState = {
    actionsLoading: false,
    expensesList: {},
    expensesTotal: '',
};

export const propertySlice = createSlice({
    name: 'property',
    initialState: initialPropertyState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        // Fetch property
        propertyFetched: (state, action) => {
            const { count } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.count = count;
        },
        // getPropertyById
        myPropertyFetched: (state, action) => {
            state.actionsLoading = false;
            state.propertyForView = action.payload.propertyForView;
            state.error = null;
        },
    },
});

export const ticketSlice = createSlice({
    name: 'ticket',
    initialState: initialTicketState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        // Fetch ticket
        ticketFetched: (state, action) => {
            // console.log(action.payload)
            const { count } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.count = count.numberOfTickets;
            state.tickets = count.tickets;
        },
    },
});

export const unitSlice = createSlice({
    name: 'unit',
    initialState: initialUnitState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        // Fetch unit
        unitFetched: (state, action) => {
            const { count, units } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.count = count;
            state.units = units;
        },
    },
});

export const employerSlice = createSlice({
    name: 'employer',
    initialState: initialEmployerState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        // Fetch employer
        employerFetched: (state, action) => {
            const { count } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.count = count;
        },
    },
});

export const tenantSlice = createSlice({
    name: 'tenant',
    initialState: initialTenantState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        // Fetch tenant
        tenantFetched: (state, action) => {
            const { count } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.count = count;
        },
    },
});

export const meetingsSlice = createSlice({
    name: 'meetings',
    initialState: initialMeetingsState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}:${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        meetingsFetched: (state, action) => {
            const { meetings } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.meetings = meetings;
        },
    },
});

export const quickStatsSlice = createSlice({
    name: 'quickStats',
    initialState: initialQuickStatsState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        // Fetch Quick Stats
        quickStatsFetched: (state, action) => {
            const {
                currency,
                amountTransacted,
                bankAccounts,
                transactionsCount,
                cashAtHandAccounts,
            } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.currency = currency;
            state.amountTransacted = amountTransacted;
            state.bankAccounts = bankAccounts;
            state.transactionsCount = transactionsCount;
            state.cashAtHandAccounts = cashAtHandAccounts;
        },
    },
});

export const recentActivitySlice = createSlice({
    name: 'recentActivity',
    initialState: initialRecentActivityState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        // Fetch Recent Activity
        recentActivityFetched: (state, action) => {
            const { signups } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.signups = signups;
        },
    },
});

export const depositsVsWithdrawalsSlice = createSlice({
    name: 'depositsVsWithdrawals',
    initialState: depositsVsWithdrawalsState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        // Fetch Deposits Vs Withdrawals
        depositsVsWithdrawalsFetched: (state, action) => {
            const { data } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.data = data;
        },
    },
});

export const loansStatsSlice = createSlice({
    name: 'loansStats',
    initialState: initialLoansStatsState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        // Fetch Loans Stats
        loansStatsFetched: (state, action) => {
            const {
                currency,
                totalLoaned,
                amountRepayed,
                amountDefaulted,
            } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.currency = currency;
            state.totalLoaned = totalLoaned;
            state.amountRepayed = amountRepayed;
            state.amountDefaulted = amountDefaulted;
        },
    },
});

export const communicationStatsSlice = createSlice({
    name: 'communicationStats',
    initialState: initialCommunicationsState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        // Fetch Stats
        communicationStatsFetched: (state, action) => {
            const { sms_count, email_count, activity } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.totalSms = sms_count;
            state.totalEmail = email_count;
            state.data = activity;
        },
    },
});

export const propertiesSummaryStatsSlice = createSlice({
    name: 'propertySummaryStats',
    initialState: initialPropertiesSummaryState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        // Fetch Stats
        propertySummaryStatsFetched: (state, action) => {
            const { properties, active_today, activity } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.AllProperties = properties;
            state.ActiveToday = active_today;
            state.data = activity;
        },
    },
});

export const incomeSlice = createSlice({
    name: 'income',
    initialState: initialIncomeState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        // Fetch Income
        incomeFetched: (state, action) => {
            const { income } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.income = income;
        },
    },
});

export const propertyDataByLandlordSlice = createSlice({
    name: 'propertyDataByLandlord',
    initialState: initialPropertyDataByLandlordState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        // Fetch PropertyDataByLandlord
        propertyDataByLandlordFetched: (state, action) => {
            const { propertyDataByLandlord } = action.payload;

            state.actionsLoading = false;
            state.error = null;
            state.landlordDataList.property_data =
                propertyDataByLandlord.property_data;
            state.landlordDataList.unit_data = propertyDataByLandlord.unit_data;
            state.landlordDataList.tenant_data =
                propertyDataByLandlord.tenant_data;
            // state.landlordDataList = propertyDataByLandlord.unit_data;
        },
    },
});
export const allUnitSlice = createSlice({
    name: 'allUnit',
    initialState: initialAllUnitState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        // Fetch AllUnit
        allUnitFetched: (state, action) => {
            const { allUnit, allUnitCount } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.allUnit = allUnit;
            state.allUnitCount = allUnitCount;
        },
    },
});
