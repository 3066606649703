/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import {
    toAbsoluteUrl,
    checkIsActive,
    hasPermission,
    // appConfigs,
} from '../../../../_helpers';
import { Badge } from 'react-bootstrap';
import { shallowEqual, useSelector } from 'react-redux';

export function AsideMenuList({ layoutProps }) {
    const location = useLocation();
    const getMenuItemActive = (url, hasSubmenu = false) => {
        // console.log("URL: " + url + ", isActive: " + checkIsActive(location, url));
        return checkIsActive(location, url)
            ? ` ${!hasSubmenu &&
                  'menu-item-active'} menu-item-open menu-item-not-hightlighted`
            : '';
    };

    const { user, settings } = useSelector(
        state => ({
            user: state.auth.user,
            settings: state.systemSettings.settings,
        }),
        shallowEqual,
    );

    const [role, setRole] = useState(undefined);
    const [permissions, setPermissions] = useState([]);

    useEffect(() => {
        if (user?.profiles?.active?.type === 'agent') {
            setPermissions(
                settings?.agentPermissions
                    ? JSON.parse(settings?.agentPermissions)
                    : [],
            );
        } else if (user?.profiles?.active?.type === 'tenant') {
            setPermissions(
                settings?.tenantPermissions
                    ? JSON.parse(settings?.tenantPermissions)
                    : [],
            );
        } else {
            setPermissions(user?.profiles?.active?.role?.permissions || []);
        }
        setRole(
            user?.profiles?.active?.is_owner === 1
                ? 'admin'
                : user?.profiles?.active?.type === 'agent'
                ? 'agent'
                : user?.profiles?.active?.type === 'tenant'
                ? 'tenant'
                : 'user',
        );
        // console.log(user?.profiles?.active.role);
    }, [user, settings]);

    return (
        <>
            {user?.profiles?.active?.type === 'tenant' ? (
                // tenant's view
                <>
                    {/* begin::Menu Nav */}
                    <ul className={`menu-nav ${layoutProps.ulClasses}`}>
                        {/* <li
                            className={`menu-item mb-5 ${getMenuItemActive(
                                '/onboard',
                                false,
                            )}`}
                            aria-haspopup="true"
                        >
                            <NavLink
                                className="menu-link onboard"
                                to="/onboard/property-info?from=menu"
                            >
                                <span className="svg-icon menu-icon svg-icon-primary">
                                    <SVG
                                        src={toAbsoluteUrl(
                                            '/media/svg/icons/Navigation/Plus.svg',
                                        )}
                                    />
                                </span>
                                <span className="menu-text font-weight-bolder text-primary">
                                    Add Property
                                </span>
                            </NavLink>
                        </li> */}
                        <li
                            className={`menu-item mt-1 ${getMenuItemActive(
                                '/dashboard',
                                false,
                            )}`}
                            aria-haspopup="true"
                        >
                            <NavLink className="menu-link" to="/dashboard">
                                <span className="svg-icon menu-icon">
                                    <SVG
                                        src={toAbsoluteUrl(
                                            '/media/svg/icons/Layout/Layout-arrange.svg',
                                        )}
                                    />
                                </span>
                                <span className="menu-text">Dashboard</span>
                            </NavLink>
                        </li>
                        <li
                            className={`menu-item mt-1 ${getMenuItemActive(
                                '/financials',
                                false,
                            )}`}
                            aria-haspopup="true"
                        >
                            <NavLink className="menu-link" to="/financials">
                                <span className="svg-icon menu-icon">
                                    <SVG
                                        src={toAbsoluteUrl(
                                            '/media/svg/icons/Shopping/Bag2.svg',
                                        )}
                                    />
                                </span>
                                <span className="menu-text">Financials</span>
                            </NavLink>
                        </li>
                    </ul>
                    {/* end::Menu Nav */}
                </>
            ) : (
                //others view - NB: to add the view for another user, add a new ternary operator here checking for user?.profiles?.active?.type
                <>
                    {/* begin::Menu Nav */}
                    <ul className={`menu-nav ${layoutProps.ulClasses}`}>
                        <li
                            className={`menu-item mb-5 ${getMenuItemActive(
                                '/onboard',
                                false,
                            )}`}
                            aria-haspopup="true"
                        >
                            <NavLink
                                className="menu-link onboard"
                                to="/onboard/property-info?from=menu"
                            >
                                <span className="svg-icon menu-icon svg-icon-primary">
                                    <SVG
                                        src={toAbsoluteUrl(
                                            '/media/svg/icons/Navigation/Plus.svg',
                                        )}
                                    />
                                </span>
                                <span className="menu-text font-weight-bolder text-primary">
                                    Add Property
                                </span>
                            </NavLink>
                        </li>
                        <li
                            className={`menu-item mt-1 ${getMenuItemActive(
                                '/dashboard',
                                false,
                            )}`}
                            aria-haspopup="true"
                        >
                            <NavLink className="menu-link" to="/dashboard">
                                <span className="svg-icon menu-icon">
                                    <SVG
                                        src={toAbsoluteUrl(
                                            '/media/svg/icons/Layout/Layout-arrange.svg',
                                        )}
                                    />
                                </span>
                                <span className="menu-text">Dashboard</span>
                            </NavLink>
                        </li>
                        {hasPermission(
                            permissions,
                            role,
                            'accessToGroupsData',
                        ) && (
                            <li
                                className={`menu-item mt-1 ${getMenuItemActive(
                                    '/properties',
                                    false,
                                )}`}
                                aria-haspopup="true"
                            >
                                <NavLink className="menu-link" to="/properties">
                                    <span className="svg-icon menu-icon">
                                        <SVG
                                            src={toAbsoluteUrl(
                                                '/media/svg/icons/Media/Equalizer.svg',
                                            )}
                                        />
                                    </span>
                                    <span className="menu-text">
                                        Properties
                                    </span>
                                </NavLink>
                            </li>
                        )}

                        {hasPermission(
                            permissions,
                            role,
                            'accessToAgentsData',
                        ) && (
                            <li
                                className={`menu-item mt-1 ${getMenuItemActive(
                                    '/tenants',
                                    false,
                                )}`}
                                aria-haspopup="true"
                            >
                                <NavLink className="menu-link" to="/tenants">
                                    <span className="svg-icon menu-icon">
                                        <SVG
                                            src={toAbsoluteUrl(
                                                '/media/svg/icons/Communication/Group.svg',
                                            )}
                                        />
                                    </span>
                                    <span className="menu-text">Tenants</span>
                                </NavLink>
                            </li>
                        )}
                        {/* tickets */}
                        {/* {hasPermission(permissions, role, 'accessToTicketsData') && (
                            <li
                                className={`menu-item mt-1 ${getMenuItemActive(
                                    '/tickets',
                                    false,
                                )}`}
                                aria-haspopup="true"
                            >
                                <NavLink className="menu-link" to="/tickets">
                                    <span className="svg-icon menu-icon">
                                        <SVG
                                            src={toAbsoluteUrl(
                                                '/media/svg/icons/Design/Layers.svg',
                                            )}
                                        />
                                    </span>
                                    <span className="menu-text">Tickets</span>
                                </NavLink>
                            </li>
                        )} */}

                        {/* expense */}
                        <li
                            className={`menu-item menu-item-submenu ${getMenuItemActive(
                                '/expenses',
                                true,
                            )}`}
                            aria-haspopup="true"
                            data-menu-toggle="hover"
                        >
                            <NavLink
                                className="menu-link menu-toggle"
                                to="/expenses"
                            >
                                <span className="svg-icon menu-icon">
                                    <SVG
                                        src={toAbsoluteUrl(
                                            '/media/svg/icons/Design/Layers.svg',
                                        )}
                                    />
                                </span>
                                <span className="menu-text">Expenses</span>
                                <i className="menu-arrow" />
                            </NavLink>
                            <div className="menu-submenu">
                                <i className="menu-arrow" />
                                <ul className="menu-subnav">
                                    <li
                                        className="menu-item menu-item-parent"
                                        aria-haspopup="true"
                                    >
                                        <span className="menu-link">
                                            <span className="menu-text">
                                                Expenses
                                            </span>
                                        </span>
                                    </li>
                                    {/*begin::2 Level*/}
                                    <li
                                        className={`menu-item ${getMenuItemActive(
                                            '/expenses/all-expenses',
                                        )}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink
                                            className="menu-link"
                                            to="/expenses/all-expenses"
                                        >
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span />
                                            </i>
                                            <span className="menu-text">
                                                All expenses
                                            </span>
                                        </NavLink>
                                    </li>
                                    {/*end::2 Level*/}
                                    {/*begin::2 Level*/}
                                    <li
                                        className={`menu-item ${getMenuItemActive(
                                            '/expenses/categories',
                                        )}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink
                                            className="menu-link"
                                            to="/expenses/categories"
                                        >
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span />
                                            </i>
                                            <span className="menu-text">
                                                Categories
                                            </span>
                                        </NavLink>
                                    </li>
                                    {/*end::2 Level*/}
                                </ul>
                            </div>
                        </li>
                        {/* billing invoice */}
                        {hasPermission(
                            permissions,
                            role,
                            'accessToAgentsData',
                        ) && (
                            <li
                                className={`menu-item mt-1 ${getMenuItemActive(
                                    '/invoices',
                                    false,
                                )}`}
                                aria-haspopup="true"
                            >
                                <NavLink className="menu-link" to="/invoices">
                                    <span className="svg-icon menu-icon">
                                        <SVG
                                            src={toAbsoluteUrl(
                                                '/media/svg/icons/Files/File-plus.svg',
                                            )}
                                        />
                                    </span>
                                    <span className="menu-text">
                                        Billing and Invoicing
                                    </span>
                                </NavLink>
                                <div className="menu-submenu">
                                    <i className="menu-arrow" />
                                    <ul className="menu-subnav">
                                        <li
                                            className="menu-item menu-item-parent"
                                            aria-haspopup="true"
                                        >
                                            <span className="menu-link">
                                                <span className="menu-text">
                                                    Billing and Invoicing
                                                </span>
                                            </span>
                                        </li>
                                        {hasPermission(
                                            permissions,
                                            role,
                                            'listingInvoices',
                                        ) && (
                                            <li
                                                className={`menu-item ${getMenuItemActive(
                                                    '/invoices/get-all',
                                                )}`}
                                                aria-haspopup="true"
                                            >
                                                <NavLink
                                                    className="menu-link"
                                                    to="/invoices/get-all"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">
                                                        Invoices
                                                    </span>
                                                </NavLink>
                                            </li>
                                        )}
                                        {hasPermission(
                                            permissions,
                                            role,
                                            'listingPayments',
                                        ) && (
                                            <li
                                                className={`menu-item ${getMenuItemActive(
                                                    '/invoices/billing-payments',
                                                )}`}
                                                aria-haspopup="true"
                                            >
                                                <NavLink
                                                    className="menu-link"
                                                    to="/invoices/billing-payments"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">
                                                        Billing Payments
                                                    </span>
                                                </NavLink>
                                            </li>
                                        )}
                                        {hasPermission(
                                            permissions,
                                            role,
                                            'accessToCreatingInvoices',
                                        ) && (
                                            <li
                                                className={`menu-item ${getMenuItemActive(
                                                    '/invoices/create-invoice',
                                                )}`}
                                                aria-haspopup="true"
                                            >
                                                <NavLink
                                                    className="menu-link"
                                                    to="/invoices/create-invoice"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">
                                                        Create Invoice
                                                    </span>
                                                </NavLink>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </li>
                        )}

                        {/* Reporting */}
                        <li
                            className={`menu-item menu-item-submenu ${getMenuItemActive(
                                '/reports',
                                true,
                            )}`}
                            aria-haspopup="true"
                            data-menu-toggle="hover"
                        >
                            <NavLink
                                className="menu-link menu-toggle"
                                to="/reports"
                            >
                                <span className="svg-icon menu-icon">
                                    <SVG
                                        src={toAbsoluteUrl(
                                            '/media/svg/icons/Shopping/Chart-pie.svg',
                                        )}
                                    />
                                </span>
                                <span className="menu-text">Reports</span>
                                <i className="menu-arrow" />
                            </NavLink>
                            <div className="menu-submenu">
                                <i className="menu-arrow" />
                                <ul className="menu-subnav">
                                    <li
                                        className="menu-item menu-item-parent"
                                        aria-haspopup="true"
                                    >
                                        <span className="menu-link">
                                            <span className="menu-text">
                                                Reports
                                            </span>
                                        </span>
                                    </li>
                                    {/*begin::2 Level*/}
                                    {/* <li
                                        className={`menu-item ${getMenuItemActive(
                                            '/reports/-properties',
                                        )}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink
                                            className="menu-link"
                                            to="/reports/-properties"
                                        >
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span />
                                            </i>
                                            <span className="menu-text">
                                                Property
                                            </span>
                                        </NavLink>
                                    </li> */}
                                    {/*end::2 Level*/}
                                    {/*begin::2 Level*/}
                                    <li
                                        className={`menu-item ${getMenuItemActive(
                                            '/reports/-tenants',
                                        )}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink
                                            className="menu-link"
                                            to="/reports/-tenants"
                                        >
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span />
                                            </i>
                                            <span className="menu-text">
                                                Tenants
                                            </span>
                                        </NavLink>
                                    </li>
                                    {/*end::2 Level*/}
                                </ul>
                            </div>
                        </li>
                        {/* Setting menu */}
                        {(hasPermission(
                            permissions,
                            role,
                            'accessToInstitutionSettings',
                        ) ||
                            hasPermission(
                                permissions,
                                role,
                                'accessToInstitutionProfileEdit',
                            ) ||
                            hasPermission(
                                permissions,
                                role,
                                'accessToInstitutionBrandingEdit',
                            ) ||
                            hasPermission(
                                permissions,
                                role,
                                'accessToUsersRead',
                            ) ||
                            hasPermission(
                                permissions,
                                role,
                                'accessToGroupsSettingsEdit',
                            )) && (
                            <li
                                className={`menu-item menu-item-submenu mt-1 ${getMenuItemActive(
                                    '/settings',
                                    true,
                                )}`}
                                aria-haspopup="true"
                                data-menu-toggle="hover"
                            >
                                <NavLink
                                    className="menu-link menu-toggle"
                                    to="/settings"
                                >
                                    <span className="svg-icon menu-icon">
                                        <SVG
                                            src={toAbsoluteUrl(
                                                '/media/svg/icons/General/Settings-2.svg',
                                            )}
                                        />
                                    </span>
                                    <span className="menu-text">Settings</span>
                                    <i className="menu-arrow" />
                                </NavLink>
                                <div className="menu-submenu">
                                    <i className="menu-arrow" />
                                    <ul className="menu-subnav">
                                        <li
                                            className="menu-item menu-item-parent"
                                            aria-haspopup="true"
                                        >
                                            <span className="menu-link">
                                                <span className="menu-text">
                                                    Settings
                                                </span>
                                            </span>
                                        </li>
                                        {hasPermission(
                                            permissions,
                                            role,
                                            'accessToInstitutionProfileEdit',
                                        ) && (
                                            <li
                                                className={`menu-item ${getMenuItemActive(
                                                    '/settings/profile',
                                                )}`}
                                                aria-haspopup="true"
                                            >
                                                <NavLink
                                                    className="menu-link"
                                                    to="/settings/profile"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">
                                                        Profile
                                                    </span>
                                                </NavLink>
                                            </li>
                                        )}
                                        {hasPermission(
                                            permissions,
                                            role,
                                            'accessToInstitutionBrandingEdit',
                                        ) && (
                                            <li
                                                className={`menu-item ${getMenuItemActive(
                                                    '/settings/theming',
                                                )}`}
                                                aria-haspopup="true"
                                            >
                                                <NavLink
                                                    className="menu-link"
                                                    to="/settings/theming"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">
                                                        Logo &amp; Theme
                                                    </span>
                                                </NavLink>
                                            </li>
                                        )}
                                        {/* {hasPermission(
                                            permissions,
                                            role,
                                            'accessToUsersRead',
                                        ) && (
                                            <li
                                                className={`menu-item ${getMenuItemActive(
                                                    '/settings/users-roles',
                                                )}`}
                                                aria-haspopup="true"
                                            >
                                                <NavLink
                                                    className="menu-link"
                                                    to="/settings/users-roles"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">
                                                        Users &amp; Roles
                                                    </span>
                                                </NavLink>
                                            </li>
                                        )} */}
                                        {hasPermission(
                                            permissions,
                                            role,
                                            'accessToUsersRead',
                                        ) && (
                                            <li
                                                className={`menu-item ${getMenuItemActive(
                                                    '/settings/backdating',
                                                )}`}
                                                aria-haspopup="true"
                                            >
                                                <NavLink
                                                    className="menu-link"
                                                    to="/settings/backdating"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">
                                                        Backdating
                                                    </span>
                                                </NavLink>
                                            </li>
                                        )}
                                        {/* {hasPermission(
                                            permissions,
                                            role,
                                            'accessToInstitutionSettings',
                                        ) && (
                                            <li
                                                className={`menu-item ${getMenuItemActive(
                                                    '/settings/system',
                                                )}`}
                                                aria-haspopup="true"
                                            >
                                                <NavLink
                                                    className="menu-link"
                                                    to="/settings/system"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">
                                                        System
                                                    </span>
                                                </NavLink>
                                            </li>
                                        )} */}
                                    </ul>
                                </div>
                            </li>
                        )}

                        {/* Ecommerce */}
                        {/* <li
                            className={`menu-item menu-item-submenu ${getMenuItemActive(
                                '/tables',
                                true,
                            )}`}
                            aria-haspopup="true"
                            data-menu-toggle="hover"
                        >
                            <NavLink
                                className="menu-link menu-toggle"
                                to="/tables"
                            >
                                <span className="svg-icon menu-icon">
                                    <SVG
                                        src={toAbsoluteUrl(
                                            '/media/svg/icons/Shopping/Bag2.svg',
                                        )}
                                    />
                                </span>
                                <span className="menu-text">Data Tables</span>
                                <i className="menu-arrow" />
                            </NavLink>
                            <div className="menu-submenu">
                                <i className="menu-arrow" />
                                <ul className="menu-subnav">
                                    <li
                                        className="menu-item menu-item-parent"
                                        aria-haspopup="true"
                                    >
                                        <span className="menu-link">
                                            <span className="menu-text">
                                                Data Tables
                                            </span>
                                        </span>
                                    </li>
                                    <li
                                        className={`menu-item ${getMenuItemActive(
                                            '/tables/customers',
                                        )}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink
                                            className="menu-link"
                                            to="/tables/customers"
                                        >
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span />
                                            </i>
                                            <span className="menu-text">
                                                Customers
                                            </span>
                                        </NavLink>
                                    </li>
                                    <li
                                        className={`menu-item ${getMenuItemActive(
                                            '/tables/products',
                                        )}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink
                                            className="menu-link"
                                            to="/tables/products"
                                        >
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span />
                                            </i>
                                            <span className="menu-text">
                                                Products
                                            </span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </li> */}
                    </ul>
                    {/* end::Menu Nav */}
                </>
            )}
        </>
    );
}
